@tailwind base;
@tailwind components;
@tailwind utilities;

/**
root 选择器表示文档的根元素。它通常用于定义全局CSS变量。
--foreground-rgb 定义了一个CSS变量，表示前景颜色的RGB值（黑色）。
--background-start-rgb 定义了另一个CSS变量，表示背景渐变的起始RGB值（浅灰色）。
--background-end-rgb 定义了背景渐变的结束RGB值（白色）。
 */
:root {
  --foreground-rgb: 0, 0, 0;
  /*--background-start-rgb: rgb(214, 219, 220);*/
  /*--background-end-rgb: rgb(255, 255, 255);*/

  --background-start-rgb: rgb(255, 192, 203);
  --background-end-rgb: rgb(255, 192, 203);
}

/**
@media (prefers-color-scheme: dark) 是一个媒体查询，用于检测用户是否偏好暗色模式。
在暗色模式下：
--foreground-rgb 被设置为白色（255, 255, 255）。
--background-start-rgb 和 --background-end-rgb 都被设置为黑色（0, 0, 0）。
 */
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
/**

body 选择器应用于文档的主体。
color: rgb(var(--foreground-rgb)) 设置文本颜色，使用前面定义的 --foreground-rgb 变量。
background 属性设置背景颜色和背景渐变：
linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) 定义了一个从透明到 --background-end-rgb 颜色（根据模式可能是白色或黑色）的线性渐变。
rgb(var(--background-start-rgb)) 设置背景颜色为 --background-start-rgb（根据模式可能是浅灰色或黑色）。

 */

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

/**
@layer utilities 是CSS层级规则，用于将样式组织成层次结构。它可以帮助开发者管理和优先级样式。
.text-balance 是一个实用类，用于设置文本换行行为。
text-wrap: normal 指定文本换行行为为正常，这意味着文本将在需要时换行。
 */
@layer utilities {
  .text-balance {
    text-wrap: normal;
  }
}
